/* DRAGON UI CORE STYLING */

html {
	background: whitesmoke;
}

.App {
	display: grid;
	grid-template-rows: 4em 1fr;
}

a {
	text-decoration: none;
	color: inherit;
}

input[type='number'],
input[type='mod'] {
	text-align: right;
	font-size: 1.7em;
}

.min-margin {
	margin: 3px 0px;
}

/* MUI OVERRIDES */
.App .MuiOutlinedInput-input {
	padding: 18.5px 8px;
}

.App .MuiFormHelperText-root {
	margin: 0;
}
